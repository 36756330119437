.splash {

    .splashImage {
        opacity: 0;
        position: fixed;
        top: -80vh;
        transition: all 700ms;
    }

    .splash-bottom {
        position: fixed;
        bottom: calc(50vh - (87px / 2));
        transition: all 700ms;

        .splashLogo {
            width: 100vw;
            height: 134px;
            margin-bottom: 16px;
            transition: all 700ms;
        }

        .splashText {
            display: block;
            font-size: 18px;
            width: 100vw;
            text-align: center;
            transition: all 700ms;
        }
    }

    &.splash-0 {
        .splash-bottom {
            opacity: 0;
        }
    }

    &.splash-1 {
        .splash-bottom {
            opacity: 1;
        }
    }

    &.splash-2, &.splash-3 {
        .splash-bottom {
            bottom: 30px;

            .splashLogo {
                height: 90px;
            }

            .splashText {
                font-size: 14px;
            }
        }
    }

    &.splash-3 {
        .splashImage {
            position: fixed;
            top: 30px;
            opacity: 1;
        }

        .splash-bottom {
            bottom: 70px;
        }
    }
}
