@import "styles/colors.scss";

.status-indicator {
    cursor: pointer;
    margin: 10px 15px 10px 0;
    & > div {
        height: 30px;
        width: 30px;
        border-radius: 7px;
        margin: 0 auto;
        position: relative;
        display: flex; 
        align-items: center; 
        justify-content: center; 
    }
    .indicator {
        width: 12px;
        height: 13px;
        position: absolute;
        border-radius: 50%;
        right: -3px;
        top: 20px;
        border: 2px solid $light-blue;
    }
    span.available {
        background-color: $green;
    }
    span.unAvailable {
        background-color: $dark-grey;
    }
    span.statusIndicatorText {
        line-height: 32px;
        background-color: $blue;
        border-radius: 50%;
        color: $theme-base-white;
        font-size: 12px;
        font-weight: 600;
        height: 30px;
        width: 30px;
        text-align: center;
    }
}
