@import "styles/colors.scss";

/*loading dots*/

.spinnerDots {
    margin: 20px auto 0;
    width: 70px;
    text-align: center;
}

.spinnerDots > div {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    background-color: $theme-base-dark;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinnerDots.small {
    margin: 0px auto 0;    
    text-align: left;
    width: unset;
}

.spinnerDots.whiteDots{
    margin: 0;
}

.spinnerDots.whiteDots > div {
    background-color: white;
}

.spinnerDots.small > div {
    width: 7px;
    height: 7px;
    margin-right: 5px;
}

.spinnerDots .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinnerDots .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
/*loading dots*/
