@import "styles/colors.scss";
    .keypad {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: -22px;
        flex: 1 1;
        .digit.big-asterisk {
            padding-top: 24px;
        }
    
        .digit {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc((100% - 50px) / 3);
            height: 70px;
            font-size: 32px;
            color: $theme-base-dark;
            font-weight: 400;
            cursor: pointer;
            border-radius: 50%;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

            &:hover {
                background-color: $light-grey-background;
            }

            .dial-letters {
                margin-top: 10px;
                font-size: 11px;
                min-height: 20px;
                color: #747474;
                letter-spacing: 2px;
            }
            .big-asterisk-text {
                font-size: 56px; 
            }
            .number {
                border-radius: 50%;
                height: 40px;
                width: 40px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: -10px;
            }
        }
    }


.active-call-keypad {
    margin-top: 20px;
    margin-bottom: 2px;
}
