@import "styles/colors.scss";
@import "styles/sizes.scss";

.header {
    height: $header-height;
    line-height: $header-height;
    justify-content: space-between;
    width: 100vw;
    font-size: 14px;
    padding: 8px 16px 0 16px;
    align-items: center;
    @media screen and (min-width: $webSize) {
        max-width: 100%;
    }

    i.wi-com-logo {
        font-size: 1.7em;
        padding: 0 0.7em;
        display: block;
        margin-top: 3px;
        color: $logo-dark;
    }
    &.dialPad {
        padding-top: 12px;
    }
}

.activeCallContainer .header {
    padding-left: 16px;
    border-bottom: none;
    color: $dark-grey;
    width: 100%;
    @media screen and (min-width: $webSize) {
        margin-left: unset;
    }
}

.bottom-tabs {
    box-shadow: 0px -2px 10px 0px rgba(59, 75, 82, 0.10);
    height: $footer-height;

    .active-indicator {
        width: 25%;
        margin: 0px 4%;
        height: 4px;
        background-color: $secondary-yellow;
        border-radius: 6px;
        position: absolute;
        transition: left 0.3s;

        &.two-tabs {
            width: 40%;
        }
    }

    .bottom-tab {
        text-align: center;
        padding: 1em 0 0.5em;

        i {
            font-size: 1.5em;
        }

        div.tab-label {
            margin-top: 0.5em;
            font-size: 10px;
            color: $theme-base-dark;
            &.selected {
                color: $green; 
            }
        }
    }
}

