@import "styles/colors.scss";

.item-container-all {
      &:hover {
        background-color: $light-grey-background;
        opacity: 1;
      }

    }
.item-container {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;

    .left-icon {
        padding: 14px 12px 0 0;
        font-size: 22px;
        &.big-padding-top {
            padding-top: 42px;
        }
        &.inner-icon {
            padding: 0 16px 0 0;
        }
    }
    .list-item {
        padding: 0.5em 0;
        flex: 1;
        .list-child {
            padding: 6px 0;
        }
        .bottom-list-child {
            padding-bottom: 6px;
            color: $dark-grey;
            font-size: 12px;
        }
    }

}

.queueListRow{
    background-color: $light-grey-background;
}


