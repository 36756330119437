@import "styles/colors.scss";

.call-button {
    color: $theme-base-white;
    background-color: $green;
    width: 64px;
    height: 64px;
    line-height: 50px;
    text-align: center;
    font-size: 32px;
    border-radius: 50%;
    padding-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: $dark-green;
    }
    &.hangup {
        background-color: $red;
    }
    &.hangup:hover {
        background-color: $dark-red;
    }
   
    &.dark {
        background-color: $light-gray-border;
        color: $theme-base-dark;
        &.active-action {
            background-color: $theme-base-white;
            color: $theme-base-dark;
            border: 1px solid $theme-base-dark;
        }
    }

    &.disabled {
        color: $disabled-grey;
        border: 1px solid $disabled-grey;
        background-color: $theme-base-white;
    }

    &.small {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 18px;
        border-radius: 16px;
        padding-top: 1.5px;
    }
}
