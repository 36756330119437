@import "styles/colors.scss";

.suggestionsContainer {
    padding-top: 15px;
    padding-bottom: 5px;
    margin: 13px;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 3px;
}
