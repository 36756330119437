@import "styles/colors.scss";

.social-button {
    display: flex;
    flex: 1;
    flex-direction: row;
    border-radius: 8px;
    margin-right: 1em;
    margin-left: 1em;
    align-items: center;
    justify-content: center;
    color: $theme-base-white;
    font-weight: 500;

    .iconContainer {
        border-radius: 8px;
        background-color: $theme-base-white;
        width: 34px;
        height: 34px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 1px;
    }
}
