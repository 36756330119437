/*--------------------------------

workizicons Web Font
Generated using nucleoapp.com

-------------------------------- */
// @font-face {
//   font-family: 'workizicons';
//   src: url('../fonts/workiz-icons.eot');
//   src: url('../fonts/workiz-icons.eot') format('embedded-opentype'), url('../fonts/workiz-icons.woff2') format('woff2'), url('../fonts/workiz-icons.woff') format('woff'), url('../fonts/workiz-icons.ttf') format('truetype'), url('../fonts/workiz-icons.svg') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
/*------------------------
	base class definition
-------------------------*/
.wfi,
[class*='wfi-']  {
  display: inline-block;
  font: normal normal normal 1em/1 'workizicons';
  color: inherit;
  flex-shrink: 0;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.wfi-sm {
  font-size: 0.8em;
}
.wfi-lg {
  font-size: 1.2em;
}
/* absolute units */
.wfi-16 {
  font-size: 16px;
}
.wfi-32 {
  font-size: 32px;
}

/*------------------------
  spinning icons
-------------------------*/
.wfi-is-spinning {
  animation: wfi-spin 1s infinite linear;
}
@keyframes wfi-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.wfi-rotate-90 {
  transform: rotate(90deg);
}
.wfi-rotate-180 {
  transform: rotate(180deg);
}
.wfi-rotate-270 {
  transform: rotate(270deg);
}
.wfi-flip-y {
  transform: scaleY(-1);
}
.wfi-flip-x {
  transform: scaleX(-1);
}
/*------------------------
	icons
-------------------------*/

.wfi-Activity::before {
  content: "\ea01";
}

.wfi-Ad-tracking::before {
  content: "\ea03";
}

.wfi-Call-flow::before {
  content: "\ea04";
}

.wfi-Call-queue::before {
  content: "\ea02";
}

.wfi-Check-fill::before {
  content: "\ea05";
}

.wfi-Check-line::before {
  content: "\ea07";
}

.wfi-Check-native::before {
  content: "\ea06";
}

.wfi-Check::before {
  content: "\ea08";
}

.wfi-Cursor-Hand::before {
  content: "\ea09";
}

.wfi-Dial-Pad::before {
  content: "\ea0b";
}

.wfi-ETA-van::before {
  content: "\ea0a";
}

.wfi-Error-fill::before {
  content: "\ea0c";
}

.wfi-Error-filled::before {
  content: "\ea0d";
}

.wfi-Error-line::before {
  content: "\ea0e";
}

.wfi-Error::before {
  content: "\ea0f";
}

.wfi-Folder::before {
  content: "\ea10";
}

.wfi-Forward-arrow::before {
  content: "\ea11";
}

.wfi-Franchises::before {
  content: "\ea12";
}

.wfi-Fuel::before {
  content: "\ea13";
}

.wfi-Headphones-Customer-Support-2::before {
  content: "\ea14";
}

.wfi-Headphones-Customer-Support::before {
  content: "\ea15";
}

.wfi-Image::before {
  content: "\ea16";
}

.wfi-In-app-phone::before {
  content: "\ea18";
}

.wfi-Incoming-call::before {
  content: "\ea17";
}

.wfi-Indeterminet-focus::before {
  content: "\ea19";
}

.wfi-Information-Circle-fill::before {
  content: "\ea1a";
}

.wfi-Information-Circle::before {
  content: "\ea1b";
}

.wfi-Item-group::before {
  content: "\ea1c";
}

.wfi-Layout-Day::before {
  content: "\ea1d";
}

.wfi-Layout-Month::before {
  content: "\ea1e";
}

.wfi-Layout-Week::before {
  content: "\ea1f";
}

.wfi-Location::before {
  content: "\ea20";
}

.wfi-Microphone-Off::before {
  content: "\ea21";
}

.wfi-Microphone-fill::before {
  content: "\ea22";
}

.wfi-Microphone::before {
  content: "\ea23";
}

.wfi-Missed-call-need-attention::before {
  content: "\ea24";
}

.wfi-Missed-call::before {
  content: "\ea25";
}

.wfi-Move-item::before {
  content: "\ea29";
}

.wfi-Native-Checkbox-Indeterminet::before {
  content: "\ea28";
}

.wfi-Native-Checkbox-Selected-Default::before {
  content: "\ea26";
}

.wfi-Native-Checkbox-Unelected-Default::before {
  content: "\ea27";
}

.wfi-Native-avatar::before {
  content: "\ea2a";
}

.wfi-Native-hamburger-menu::before {
  content: "\ea2b";
}

.wfi-Native-hour-clock::before {
  content: "\ea2c";
}

.wfi-Native-location-pin::before {
  content: "\ea2d";
}

.wfi-Native-notification-bell-fill::before {
  content: "\ea2e";
}

.wfi-Outgoing-call-not-answer::before {
  content: "\ea2f";
}

.wfi-Outgoing-call::before {
  content: "\ea30";
}

.wfi-Phone-Actions-Next::before {
  content: "\ea31";
}

.wfi-Phone-Actions-Pause::before {
  content: "\ea33";
}

.wfi-Phone-Actions-Ring::before {
  content: "\ea32";
}

.wfi-Phone::before {
  content: "\ea34";
}

.wfi-Pricebook::before {
  content: "\ea35";
}

.wfi-Question-Circle-fill::before {
  content: "\ea36";
}

.wfi-Question-Circle::before {
  content: "\ea37";
}

.wfi-Sales::before {
  content: "\ea38";
}

.wfi-Selected-focus::before {
  content: "\ea39";
}

.wfi-Service-plans::before {
  content: "\ea3a";
}

.wfi-Settings::before {
  content: "\ea3b";
}

.wfi-Sign::before {
  content: "\ea3c";
}

.wfi-Speed::before {
  content: "\ea3d";
}

.wfi-Tax::before {
  content: "\ea3e";
}

.wfi-Taxable-item::before {
  content: "\ea3f";
}

.wfi-Time-Clock-Circle-2::before {
  content: "\ea40";
}

.wfi-Time-Clock-Circle::before {
  content: "\ea41";
}

.wfi-Tip::before {
  content: "\ea42";
}

.wfi-Truck::before {
  content: "\ea43";
}

.wfi-Unelected-focus_1::before {
  content: "\ea44";
}

.wfi-Unscheduled-jobs::before {
  content: "\ea45";
}

.wfi-View-as-client::before {
  content: "\ea46";
}

.wfi-Voicemail::before {
  content: "\ea47";
}

.wfi-Warning-Circle-fill::before {
  content: "\ea48";
}

.wfi-Warning-Circle::before {
  content: "\ea49";
}

.wfi-add-contact::before {
  content: "\ea4a";
}

.wfi-add-payment-method::before {
  content: "\ea4b";
}

.wfi-add::before {
  content: "\ea4c";
}

.wfi-adjust-price---filled::before {
  content: "\ea4d";
}

.wfi-adjust-price---outlined::before {
  content: "\ea4e";
}

.wfi-adjust-price::before {
  content: "\ea4f";
}

.wfi-air-conditioning::before {
  content: "\ea50";
}

.wfi-air-duct::before {
  content: "\ea51";
}

.wfi-approval::before {
  content: "\ea52";
}

.wfi-archive::before {
  content: "\ea53";
}

.wfi-asset-tracking::before {
  content: "\ea54";
}

.wfi-asset::before {
  content: "\ea55";
}

.wfi-assign::before {
  content: "\ea56";
}

.wfi-attach::before {
  content: "\ea57";
}

.wfi-auto-glass::before {
  content: "\ea58";
}

.wfi-automation::before {
  content: "\ea59";
}

.wfi-back-arrow::before {
  content: "\ea5a";
}

.wfi-back-chevron::before {
  content: "\ea5b";
}

.wfi-back-delete-full::before {
  content: "\ea5c";
}

.wfi-back-delete::before {
  content: "\ea5d";
}

.wfi-balances::before {
  content: "\ea5e";
}

.wfi-bank::before {
  content: "\ea5f";
}

.wfi-big-2::before {
  content: "\ea61";
}

.wfi-big::before {
  content: "\ea60";
}

.wfi-billing::before {
  content: "\ea62";
}

.wfi-block-caller::before {
  content: "\ea63";
}

.wfi-block::before {
  content: "\ea64";
}

.wfi-call-masking::before {
  content: "\ea65";
}

.wfi-call::before {
  content: "\ea66";
}

.wfi-camera::before {
  content: "\ea67";
}

.wfi-card-reader::before {
  content: "\ea69";
}

.wfi-carpet-cleaning::before {
  content: "\ea68";
}

.wfi-cash::before {
  content: "\ea6a";
}

.wfi-change-category---filled::before {
  content: "\ea6b";
}

.wfi-change-category---outlined::before {
  content: "\ea6c";
}

.wfi-change-category::before {
  content: "\ea6d";
}

.wfi-change-item::before {
  content: "\ea6e";
}

.wfi-circle-2::before {
  content: "\ea6f";
}

.wfi-circle-3::before {
  content: "\ea70";
}

.wfi-circle-fill::before {
  content: "\ea71";
}

.wfi-circle::before {
  content: "\ea72";
}

.wfi-cleaning-services::before {
  content: "\ea73";
}

.wfi-clients-users::before {
  content: "\ea75";
}

.wfi-commissions::before {
  content: "\ea74";
}

.wfi-consumer-financing::before {
  content: "\ea76";
}

.wfi-contracting::before {
  content: "\ea77";
}

.wfi-convert-to-job-2::before {
  content: "\ea78";
}

.wfi-convert-to-job::before {
  content: "\ea79";
}

.wfi-copy::before {
  content: "\ea7a";
}

.wfi-credict-card::before {
  content: "\ea7b";
}

.wfi-credit-check::before {
  content: "\ea7c";
}

.wfi-custom-items::before {
  content: "\ea7d";
}

.wfi-default::before {
  content: "\ea7e";
}

.wfi-delete::before {
  content: "\ea7f";
}

.wfi-dispatch::before {
  content: "\ea80";
}

.wfi-down-2::before {
  content: "\ea81";
}

.wfi-down::before {
  content: "\ea82";
}

.wfi-download::before {
  content: "\ea83";
}

.wfi-draft::before {
  content: "\ea84";
}

.wfi-edit::before {
  content: "\ea85";
}

.wfi-electrical::before {
  content: "\ea88";
}

.wfi-ellipsis-kebab-menu::before {
  content: "\ea86";
}

.wfi-email::before {
  content: "\ea87";
}

.wfi-erase-clear::before {
  content: "\ea89";
}

.wfi-estimate::before {
  content: "\ea8a";
}

.wfi-export::before {
  content: "\ea8b";
}

.wfi-fields::before {
  content: "\ea8c";
}

.wfi-files::before {
  content: "\ea8d";
}

.wfi-fill::before {
  content: "\ea8e";
}

.wfi-filter-1::before {
  content: "\ea90";
}

.wfi-filter-2::before {
  content: "\ea8f";
}

.wfi-finance::before {
  content: "\ea91";
}

.wfi-flag::before {
  content: "\ea92";
}

.wfi-forward-message::before {
  content: "\ea93";
}

.wfi-garage-doors::before {
  content: "\ea94";
}

.wfi-getting-started-rocket::before {
  content: "\ea95";
}

.wfi-hamburger-Menu::before {
  content: "\ea97";
}

.wfi-handyman::before {
  content: "\ea96";
}

.wfi-hang-up::before {
  content: "\ea98";
}

.wfi-hide::before {
  content: "\ea9a";
}

.wfi-hold-fill::before {
  content: "\ea99";
}

.wfi-hold::before {
  content: "\ea9b";
}

.wfi-inventory::before {
  content: "\ea9c";
}

.wfi-invoice::before {
  content: "\ea9d";
}

.wfi-it-services::before {
  content: "\ea9f";
}

.wfi-item-type---filled::before {
  content: "\ea9e";
}

.wfi-item-type---outlined::before {
  content: "\eaa0";
}

.wfi-janitor::before {
  content: "\eaa1";
}

.wfi-job::before {
  content: "\eaa2";
}

.wfi-jobs-statistics::before {
  content: "\eaa3";
}

.wfi-junk-removal::before {
  content: "\eaa4";
}

.wfi-landscaping::before {
  content: "\eaa5";
}

.wfi-layout-3-Days::before {
  content: "\eaa6";
}

.wfi-layout-Agenda::before {
  content: "\eaa7";
}

.wfi-layout-options::before {
  content: "\eaa8";
}

.wfi-leads::before {
  content: "\eaa9";
}

.wfi-link::before {
  content: "\eaaa";
}

.wfi-load-template::before {
  content: "\eaab";
}

.wfi-locksmith::before {
  content: "\eaac";
}

.wfi-login::before {
  content: "\eaad";
}

.wfi-logout::before {
  content: "\eaae";
}

.wfi-lost::before {
  content: "\eaaf";
}

.wfi-map::before {
  content: "\eab0";
}

.wfi-marked::before {
  content: "\eab1";
}

.wfi-marketplace-2::before {
  content: "\eab2";
}

.wfi-marketplace::before {
  content: "\eab3";
}

.wfi-message::before {
  content: "\eab6";
}

.wfi-messaging-status-received::before {
  content: "\eab5";
}

.wfi-messaging-status-sent::before {
  content: "\eab4";
}

.wfi-navigate-location::before {
  content: "\eab7";
}

.wfi-note::before {
  content: "\eab8";
}

.wfi-notification-Bell::before {
  content: "\eab9";
}

.wfi-online-booking::before {
  content: "\eaba";
}

.wfi-open-in-new-tab::before {
  content: "\eabb";
}

.wfi-painting::before {
  content: "\eabc";
}

.wfi-pdf-2::before {
  content: "\eabe";
}

.wfi-pdf::before {
  content: "\eabf";
}

.wfi-pest-control::before {
  content: "\eabd";
}

.wfi-phone-off::before {
  content: "\eac0";
}

.wfi-plumbing::before {
  content: "\eac1";
}

.wfi-pool-and-spa-services::before {
  content: "\eac2";
}

.wfi-premium-filled::before {
  content: "\eac3";
}

.wfi-print::before {
  content: "\eac4";
}

.wfi-proposal::before {
  content: "\eac5";
}

.wfi-quick-reply::before {
  content: "\eac6";
}

.wfi-quick::before {
  content: "\eac7";
}

.wfi-rates::before {
  content: "\eac8";
}

.wfi-receipt::before {
  content: "\eac9";
}

.wfi-recurring-job::before {
  content: "\eaca";
}

.wfi-recurring::before {
  content: "\eacb";
}

.wfi-refresh::before {
  content: "\eacc";
}

.wfi-refund::before {
  content: "\eacd";
}

.wfi-reports::before {
  content: "\eace";
}

.wfi-reschedule::before {
  content: "\eacf";
}

.wfi-right::before {
  content: "\ead0";
}

.wfi-roofing::before {
  content: "\ead3";
}

.wfi-save::before {
  content: "\ead1";
}

.wfi-search::before {
  content: "\ead2";
}

.wfi-security::before {
  content: "\ead4";
}

.wfi-send::before {
  content: "\ead5";
}

.wfi-share::before {
  content: "\ead6";
}

.wfi-shine::before {
  content: "\ead7";
}

.wfi-shortcode::before {
  content: "\ead8";
}

.wfi-small-avatar::before {
  content: "\ead9";
}

.wfi-snow-removal::before {
  content: "\eada";
}

.wfi-sort-down::before {
  content: "\eadb";
}

.wfi-sort-up::before {
  content: "\eadc";
}

.wfi-speaker-fiil::before {
  content: "\eadd";
}

.wfi-speaker::before {
  content: "\eadf";
}

.wfi-star-favorite::before {
  content: "\eade";
}

.wfi-start::before {
  content: "\eae1";
}

.wfi-stock---filled::before {
  content: "\eae0";
}

.wfi-stock::before {
  content: "\eae2";
}

.wfi-stop::before {
  content: "\eae3";
}

.wfi-sync::before {
  content: "\eae4";
}

.wfi-tags::before {
  content: "\eae6";
}

.wfi-task-done::before {
  content: "\eae5";
}

.wfi-task::before {
  content: "\eae8";
}

.wfi-taxable-item---filled::before {
  content: "\eae7";
}

.wfi-taxable-item---outlined::before {
  content: "\eaeb";
}

.wfi-team::before {
  content: "\eaea";
}

.wfi-template::before {
  content: "\eae9";
}

.wfi-time-off::before {
  content: "\eaec";
}

.wfi-timeline::before {
  content: "\eaed";
}

.wfi-timer::before {
  content: "\eaee";
}

.wfi-toggle---filled::before {
  content: "\eaef";
}

.wfi-toggle---otlined::before {
  content: "\eaf0";
}

.wfi-tree-care::before {
  content: "\eaf1";
}

.wfi-unarchive::before {
  content: "\eaf2";
}

.wfi-unread-message-2::before {
  content: "\eaf3";
}

.wfi-unread-message::before {
  content: "\eaf4";
}

.wfi-up-2::before {
  content: "\eaf5";
}

.wfi-up::before {
  content: "\eaf6";
}

.wfi-update-change-status::before {
  content: "\eaf7";
}

.wfi-user-account::before {
  content: "\eaf8";
}

.wfi-view::before {
  content: "\eaf9";
}

.wfi-website-requests::before {
  content: "\eafa";
}

.wfi-whats-new::before {
  content: "\eafb";
}

.wfi-window-cleaning::before {
  content: "\eafc";
}

.wfi-won::before {
  content: "\eafd";
}

.wfi-workiz-logo-outline::before {
  content: "\eafe";
}

.wfi-workiz-logo::before {
  content: "\eaff";
}

.wfi-workiz-pay-1::before {
  content: "\eb00";
}

.wfi-workiz-pay::before {
  content: "\eb01";
}

.wfi-x::before {
  content: "\eb02";
}

.wfi-zoom::before {
  content: "\eb03";
}

