@import "styles/sizes.scss";
@import "styles/basic-styles.scss";

.tab-container {
    position: fixed;
    height: calc(100vh - #{$footer-height} - #{$header-height});
    width: 100vw;
    display: flex;
    flex-direction: column;
    transition: opacity ease-in-out 500ms;
    overflow: auto;
    @media screen and (min-width: $webSize) {
        position: absolute;
        height: 100%;
        width: 100%;
    }
    input.dial-pad-search {
        text-align: center;
    }
}
