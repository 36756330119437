@import "styles/colors.scss";
@import "styles/sizes.scss";

.modal-container {
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    height: 100vh;
    width: 100vw;
    left: 0;
    bottom: -100vh;
    z-index: 4;

    &.visible {
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.2);
        transition: background-color 500ms ease-in-out;
    }

    @media screen and (min-width: $webSize) {
        max-width: 100%;
        max-height: 100%;
    }
}

.modal {
    position: fixed;
    max-height: 90vh;
    min-height: 25vh;
    width: 100vw;
    background-color: $theme-base-white;
    left: 0;
    bottom: -100vh;
    border-radius: 30px 30px 0 0;
    box-shadow: 0px -1px 14px rgba(0, 0, 0, 0.04);
    z-index: 5;
    padding-bottom: 1em;

    &.visible {
        bottom: 0;
    }

    &.fullscreen {
        height: 100vh;
        min-height: 100vh;
        border-radius: 0;
        .modal-header {
            padding: 12px;
            & > div {
                display: flex;
                flex-direction: row;
                cursor: pointer;
                align-items: flex-end;
            }
            
        }
        .modal-title {
            padding-left: 16px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .modal-title {
        font-weight: 600;
        font-size: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        line-height: normal;
    }

    .modal-header {
        padding: 16px 12px 0 16px;

        &.dark {
            color: $theme-base-white;
            background: $gradient-blue;
        }
    }

    .modal-close {
        color: #666;
    }

    &.full-screen-fixed-size {
        height: 90%;
    }

    @media screen and (min-width: $webSize) {
        min-height: unset !important;
        max-width: 100%;
        max-height: 100%;
        bottom: -100%;
        visibility: hidden;

        &.visible {
            visibility: visible;
        }
    }
    .close-icon{
        font-size: 24px;
    }
}
