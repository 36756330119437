@import "styles/colors.scss";

.container {
    height: 48px;
    border-bottom: 1px solid $light-border-grey;
    padding: 0 30px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
        flex: 1;
        font-weight: 600;
    }

    .logo {
        font-size: 1.7em;
        margin-top: 8px;
    }
}
