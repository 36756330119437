.verticalAlignContainer {
    height: 100%;
    position: relative;
    .verHorCenter {
        margin: 0;
        text-align: center;
        img{
            max-width: 160px;
            margin-top: 80px;
        }
        h3{
            font-size: 16px;
            padding: 0 20px;
        }
    }
} 
