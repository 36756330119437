@import "styles/colors.scss";

.action-button {
    cursor: pointer;
    flex: 1; 
    border-radius: 4px;
    padding: 4px 0;
    background: $action-button-background;
    margin-right: 16px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $blue;
    gap: 4px;
    &:hover {
        background-color: $action-button-hover;
    }
    &:active {
        background-color: $action-button-active;
    }
    .action-icon {
        display: flex;
        font-size: 22px;
        justify-content: center;
        align-items: center;
        
    }
    .action-text {
        font-size: 11px;
    }
    
}

.action-button.active-call {
    flex-direction: row;
    background-color: $light-grey-background;
    color: $theme-base-dark;
    border-radius: 8px;
    cursor: pointer;
    padding: 12px 10px;
    &:hover {
        background-color: $light-gray-border;
    }
    &:active {
        background-color: $light-grey;
    }
}

.action-icon.active-call {
    font-size: 24px;
}

.action-text.active-call {
    line-height: 19px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.4px;
}

.action-button.call-details {
    padding: 8px 0;
}

.action-button:last-child {
    margin-right: 0; 
}

