@import "styles/colors.scss";

.selected-label {
    cursor: pointer;
    color: $theme-base-dark;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.4px;
}

.select-icon {
    font-size: 16px;
}

.option {
    width: 16px;
    height: 16px;
    border: 2px solid $light-grey;
    border-radius: 50%;
    margin-bottom: 4px;
    &.selected-item {
        border: 5px solid $blue;
    }
}