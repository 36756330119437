@import 'styles/basic-styles.scss';

.call_notifications {
    display: flex;
    justify-content: center;
}

.call_notifications_inner {
    width: 90%;
    top: 24px;
    background-color: $light-pinkish;
    text-align: center;
    position: fixed;
    z-index: 999;
    padding: 12px 12px 12px 8px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 4px solid $red;
    box-shadow: 0px 0px 4px 0px rgba(59, 75, 82, 0.05), 0px 8px 16px 0px rgba(59, 75, 82, 0.15);
}

.call_notifications_text {
    font-size: 14px;
    font-weight: 400;
    padding-left: 8px;
    line-height: 18px; 
    max-width: 90%;
    text-align: start;
}


.call_notification_icon {
    color: $red;
    font-size: 18px;
}

.left_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.close_icon {
    font-size: 18px;
    color: $dark-grey;
    display: inline-flex;
}