/* wi stands for Workiz Icon */
[class*="wi-"]:before {
    display: inline-block;
    font-family: "WorkizIconsOld";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wi-phone-hangup:before { content: "\0042"; }
.wi-zoom:before { content: "\0031"; }
.wi-full-circle:before { content: "\0049"; }
.wi-pause:before { content: "\004A"; }
.wi-add-user:before { content: "\004B"; }
.wi-phone-hangup-outline:before { content: "\004C"; }
.wi-speaker-active:before { content: "\004D"; }
.wi-transfer:before { content: "\004E"; }
.wi-dial-pad:before { content: "\004F"; }
.wi-voicemail:before { content: "\0050"; }
.wi-workiz-icon:before { content: "\0051"; }
.wi-mute:before { content: "\0052"; }
.wi-un-mute:before { content: "\0053"; }
.wi-headset:before { content: "\0031"; }
.wi-phone:before { content: "\0054"; }
.wi-users:before { content: "\0055"; }
.wi-history:before { content: "\0056"; }
.wi-cross:before { content: "\0057"; }
.wi-plus:before { content: "\0058"; }
.wi-bin:before { content: "\0059"; }
.wi-downland:before { content: "\005A"; }
.wi-pencil:before { content: "\0061"; }
.wi-funnel:before { content: "\0062"; }
.wi-erase:before { content: "\0063"; }
.wi-open:before { content: "\0064"; }
.wi-camera:before { content: "\0065"; }
.wi-search:before { content: "\0066"; }
.wi-location:before { content: "\0067"; }
.wi-note:before { content: "\0068"; }
.wi-user:before { content: "\0069"; }
.wi-chevron-right:before { content: "\006A"; }
.wi-chevron-bottom:before { content: "\0036"; }
.wi-chevron-left:before { content: "\006B"; }
.wi-calendar:before { content: "\006D"; }
.wi-image:before { content: "\006D"; }
.wi-copy:before { content: "\006E"; }
.wi-check-mark:before { content: "\006F"; }
.wi-bubbles:before { content: "\0070"; }
.wi-send:before { content: "\0071"; }
.wi-phone-outline:before { content: "\0072"; }
.wi-in-app-send:before { content: "\0073"; }
.wi-email-send:before { content: "\0074"; }
.wi-sms-send:before { content: "\0075"; }
.wi-paperclip:before { content: "\0076"; }
.wi-tag-thin:before { content: "\0078"; }
.wi-doc-dollar:before { content: "\0079"; }
.wi-gear:before { content: "\007A"; }
.wi-clock:before { content: "\0030"; }
.wi-headset-thin:before { content: "\0031"; }
.wi-exit:before { content: "\0032"; }
.wi-com-logo:before { content: "\0037"; }
.wi-job:before { content: "\0033"; }
.wi-lead:before { content: "\0034"; }
.wi-phone-cross:before { content: "\0035"; }
