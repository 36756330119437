
@font-face {
    font-family: 'workizicons';
    src: url('../assets/fonts/workiz-icons.eot?v=2');
    src: url('../assets/fonts/workiz-icons.eot?v=2') format('embedded-opentype'), 
         url('../assets/fonts/workiz-icons.woff2?v=2') format('woff2'), 
         url('../assets/fonts/workiz-icons.woff?v=2') format('woff'), 
         url('../assets/fonts/workiz-icons.ttf?v=2') format('truetype'), 
         url('../assets/fonts/workiz-icons.svg?v=2') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'WorkizIconsOld';
    src: url('../assets/fonts/WorkizIconsOld.ttf') format('truetype');
}

@font-face {
    font-family: 'poppins';
    src: local('poppins'), url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

.font-16 {
    font-size: 16px;
}

.font-14 {
    font-size: 14px;
}

.font-24 {
    font-size: 24px;
}
