@import "styles/colors.scss";
.header-list {
    color: $theme-base-dark;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding: 20px 16px;
}
.callDescLine {
    margin-top: 32px;
    margin-bottom: 16px;
}

.callDescNumber {
    margin-top: -8px;
}

.historyText {
    line-height: 23px;
}

.capitalized {
    text-transform: capitalize;
}
.add-new-container {
    margin: 16px 0 32px 0;
    .add-new {
        display: flex;
        height: 36px;
        padding: 8px 32px 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 24px;
        border: 1px solid $theme-base-dark;
        font-weight: 600;
    }
}


.info-list {
    margin: 1em 0;
    background-color: $light-grey-background;
    border-radius: 4px;
    .icon-container {
        font-size: 18px;
        display: inline-flex;
    }
}


.left-top-list {
    display: inline-block;
    background-color: $light-grey-text;
    padding: 4px;
    border-radius: 4px;
    margin-top: 8px;
}

.list-right-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 3px;
    padding: 3px;
    i {
        margin: auto;
      }
   
}
.text-right-buttons {
    margin-left: 3px;
    margin-top: 2px;
}
.right-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    border-left: 1px solid $light-gray-border;
    padding: 8px 0 8px 8px;
    gap: 12px;
}


