@import "styles/colors.scss";
@import "styles/sizes.scss";

input.floatingLabelInput {
    width: 100%;
    padding: 0 2em;
    height: 50px;
    border-radius: 2em;
    border: 1px solid $light-gray-border;

    &:focus {
        border: 1px solid $green;
        border-radius: 25px;
    }

    &.with-error {
        border: 1px solid $red;
    }
}

.btnRadio {
    border-radius: 4px;
    border: 2px solid $light-grey-background;
    overflow: hidden;
    transition: 0.2s background-color;
    background-color: $light-grey-background;

    .btnRadioBtn {
        padding: 10px;
        text-align: center;
        cursor: pointer;
    }

    .selected {
        background-color: $theme-base-white;
        color: $blue;
        font-weight: 600;
        box-shadow: 0px 0px 4px 0px rgba(59, 75, 82, 0.10), 0px 2px 4px 0px rgba(59, 75, 82, 0.05);
    }
}

.send-recording-form {
    padding-left: 16px;
    padding-right: 16px;
    .header-send-recording {
        color: $theme-base-dark;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 24px;
    }

    .recording-button {
        border: none !important;
        height: 40px;
    }

    input, textarea {
        font-family: Poppins;
        margin-top: 20px;
        border: 1px solid #ccc;
        border-radius: 2px;
        padding: 0 10px;
        transition: border 0.3s;
        background: #f7f7f7;
        box-shadow: none !important;
        outline: none;
        
        &:focus {
            border: 1px solid #ffd400 !important;
        }

    }

    input {
        color: $dark-grey;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.4px;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 2px;
        padding: 0px 10px;
        transition: border 0.3s;
        background: $theme-base-white;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        margin-bottom: 2px;
    }

    textarea {
        color: $theme-base-dark;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.4px;
        width: 100%;
        overflow: hidden;
        height: 75px;
        background-color: $theme-base-white;
        padding-top: 10px;
    }
}

.input-error {
    padding: 0;
    margin-left: 2em;
    margin-top: 0.2em;
}

.submitButton {
    height: 50px;
    border-radius: 25px;
    line-height: 50px;
    text-align: center;
    color: #3e4b51 !important;
    background-color: $main-yellow;
    line-height: 22px;
    padding: 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:active {
        opacity: 0.7;
    }

    &.disabled,
    &.cancel {
        border: 1px solid $theme-base-dark;
        background-color: $theme-base-white;
    }
    &.without-border {
        border: none;
    }

    &.small {
        line-height: 22px;
        padding: 0px 12px;
        font-size: 13px;
        font-weight: 600;
        font-family: Poppins;
    }

    &.disabled {
        cursor: not-allowed;
    }
}

.search-bar {
    input {
        &.no-border {
            border: none;
        }
        &.big {
            font-size: 32px;
            &::placeholder {
                font-size: 24px;
            }
        }
        &.small {
            font-size: 24px;
        }
    }

    i.search-icon {
        align-self: center;
        font-size: 20px;
        transition: all 300ms;
        position: relative;

        &.small {
            font-size: 14px;
        }

        @media screen and (min-width: $webSize) {
            display: flex;
        }
    }
    i.dial-pad-search-icon {
        position: absolute;
        right: 50px;
        bottom: 32px;
        font-size: 32px;
        cursor: pointer;
    }
}

.keypad-container {
    padding:24px 30px 0 30px ;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    .buttons-container-keypad {
        margin-top: 32px;
        margin-bottom: 16px;
    }
}