@import "styles/colors.scss";
@import "styles/sizes.scss";

.activeCallContainer {
  background-color: $theme-base-white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 3;
    .header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;
        margin: 1em 2em;
        .contact-name {
            font-size:24px;
            line-height: 37px;
        }
    }
   
    .conference-header {
        font-size:18px;
        line-height: 27px;
    }
    .timer {
        line-height: 27px; 
        letter-spacing: 0.2px;
        font-weight: 400;
    }
    .action-buttons-container {
        width: 100%;
        padding: 0 16px;
        margin-bottom: 1em;
    }
    .modal,
    .suggestions {
        color: $theme-base-dark;
    }
    &.incoming-active {
        .header {
            z-index: 3;
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
        }
      
        &::before {
          background: $theme-base-dark;
          z-index: 1; 
          bottom: 200px;
          left: 0;
          filter: blur(60px);
        }
      
        &::after {
          background: $cover-background-incoming;
          z-index: 2; 
        }

        .incoming-buttons {
          position: relative;
          z-index: 3; 
          margin-bottom: 72px;
          .margin-right-button {
            margin-right: 48px;
          }
        }
        .incoming-call-header {
          font-size: 18px;
          line-height: 27px; 
          letter-spacing: 0.2px;
          position: relative;
          z-index: 3; 
        }
        .caller-name {
          font-size: 25px;
          margin-top: 8px;
          margin-bottom: 8px;
          line-height: 37px;
          letter-spacing: 0.4px;
          position: relative;
          z-index: 3; 
        }
        .incoming-call-info {
          border-radius: 8px;
          background: $dark-grey-background;
          padding: 8px 16px;
          display: inline-block;
          text-align: left;
          margin-top: 24px;
          line-height: 24px;
          font-size: 16px;
          position: relative;
          z-index: 3;
          .info-row:not(:last-child) {
            border-bottom: 1px solid $dark-grey;
            padding-bottom: 8px; 
          }
          .info-row:nth-child(2):not(:only-child) {
            padding-top: 8px;
          }
        }

        .additional-client-info{
          font-size: 13px;
          position: relative;
          z-index: 3;
          margin-bottom: 8px;
          margin-top: 8px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: $light-border-grey;

          .additional-client-info-bold{
            font-weight: 600;
          }
        }

      }
      

    .actionsRow {
        display: flex;
        flex-direction: row;
        margin-bottom: 2em;
        background-color: $light-grey-background;
        border-radius: 8px;
        padding:16px;
        justify-content: space-around;
        gap: 16px;
        flex-wrap: wrap;
        min-height: 340px;
        &.conference-actions {
            padding: 0 8px;
        }
        .callAction {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            width: calc((100% - 35px) / 3);
            gap: 4px;
            cursor: pointer;
            i::before {
              height: 40px;
              font-size: 32px;
              font-weight: 400;
             
            }
            i:active {
                color: $green;
            }
            .icon-container {
                border-radius: 50%;
                padding: 6px 8px;
                &:hover {
                    background-color: $light-gray-border;
                }
            }
            .call-action-title {
              text-align: center;
            }

            .call-action-title {
              white-space: nowrap; 
              overflow: hidden;
              text-overflow: ellipsis;
            }

          }
        .callAction:nth-child(-n+3) {
            margin-top: 16px; 
        }
        .hideButton {
            display: inline-flex;
            padding: 4px 8px;
            align-items: center;
            grid-gap: 8px;
            gap: 8px;
            position: absolute;
            bottom: 42px;
            right: 40%;
            border-radius: 45px;
            border: 1px solid $light-gray-border;
            background: $theme-base-white;
            cursor: pointer;
        }
    }
    @media screen and (min-width: $webSize) {
        padding: unset;

        & > div:last-child {
            padding: 0;
        }
    }
}
._clearLink,
a._clearLink {
    text-decoration: underline;
    color: #3da6e1;
    cursor: pointer;
}

.hoverLink,
a.hoverLink {
    cursor: pointer;
    
    &:hover {
        color: #3da6e1;
        text-decoration: underline;
    }
}

.recordingLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark-grey;
    position: absolute;
    bottom: 8px;
    left: 75px;
    &>.logo {
        background-color: $red;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        padding: 0;
        margin-right: 8px;
        position: relative;
        overflow: hidden;
      }
      .logo::before {
        content: '';
        background-color: transparent; 
        border: 1.5px solid $theme-base-white; 
        border-radius: 50%;
        height: 4px;
        width: 4px;
        position: absolute;
        top: 50%; 
        left:50% ; 
        transform: translate(-50%, -50%); 
      }
      &>.logo.standby {
        background-color: $theme-base-white;
        border: 1.5px solid $dark-grey; 
      }
      .logo.standby::before {
        border: 1.5px solid $dark-grey;
       
      }
}

