$main-yellow: #fad400;
$secondary-yellow: #ffd100;
$light-border-grey: #ededed;
$border-grey: #c4c4c4;
$dark-border: #ddd;
$theme-base-white: #fff;
$theme-base-dark: #3B4B52;
$cover-background-incoming: rgba(59, 75, 82, 0.70);
$gradient-blue: linear-gradient(180deg, $theme-base-dark 0%, #3d6677 100%);
$red: #f45e44;
$dark-red: #D42A0C;
$text_grey: #a5a5a5;
$green: #87dcbf;
$light-blue: #fafcfc;
$disabled-grey: #d8d8d8;
$logo-dark: #3A4B52;
$blue: #6AA8EE;
$green: #3ACF7D;
$dark-green: #2CB569;
$dark-grey: #768287;
$dark-grey-background: #566D76;
$light-grey: #BFC4C7;
$light-grey-text:#9EA6AA;
$light-grey-background: #F3F6F7;
$tooltip-gray: #505d64;
$light-gray-border: #DFE2E3;
$light-pinkish: #FEEFEC;
$action-button-background: #E5F1FF;
$action-button-hover: #C2DEFF;
$action-button-active: #9FC7F5;

.green {
    color: $green;
}

.red {
    color: $red;
}

.grey {
    color: $text_grey;
}

.blue {
    color: $blue;
}

.white {
    color: $theme-base-white;
}

.blue-background {
    background-color: $blue;
}

.green-background {
    background-color: $green;
}

.bright-text {
    color: $light-grey-text;
}
