@import "./reset.scss";
@import "./colors.scss";
@import "./fonts.scss";
@import "./icons.scss";
@import "./workizIcons.scss";

$webSize: 350px;
$popupSize: 320px;
$circleSize: 13px;
$circleColor: $red;
$strokeColor: $theme-base-white;

body,
input::placeholder,
input {
    margin: 0;
    font-family: poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $theme-base-dark;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

#root {
    @media screen and (min-width: $webSize) {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
    }
}

.no-margin {
    margin: 0;
}
.margin-bottom {
    margin-bottom: 1em;
}
.small-margin-bottom {
    margin-bottom: 0.5em;
}
.margin-right {
    margin-right: 1em;
}
.margin-left {
    margin-left: 1em;
}
.small-margin-right {
    margin-right: 0.5em;
}
.small-margin-left {
    margin-left: 0.5em;
}
.mini-margin-left {
    margin-left: 3px;
}
.mini-margin-right {
    margin-right: 3px;
}
.mini-margin-top {
    margin-top: 3px;
}
.margin-horizontal, .padding-horizontal {
    padding-right: 1em;
    padding-left: 1em;
}
.padding-vertical {
    padding-top: 1em;
    padding-bottom: 1em;
}
.padding-bottom-only {
    padding-bottom: 1em;
}
.small-padding-vertical {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}
.big-margin-horizontal {
    padding-right: 50px;
    padding-left: 50px;
}
.margin-top {
    margin-top: 1em;
}
.big-margin-top {
    margin-top: 3em;
}
.mini-margin-top {
    margin-top: 0.2em;
}
.margin-all {
    margin: 2em;
}
.small-margin-all {
    margin: 1em;
}
.small {
    font-size: 0.9em;
}

.bold {
    font-weight: 600;
}

.pointer {
    cursor: pointer;
}

.animated {
    transition: all 500ms;
}

.flex-one {
    flex: 1;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-end {
    display: flex;
    align-items: flex-end;
}

.center-content {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.center-text {
    text-align: center;
}

.full-size {
    width: 100vw;
    height: 100vh;
    @media screen and (min-width: $webSize) {
        max-width: 320px;
        max-height: 595px;
    }
}

img {
    object-fit: contain;
}

.ripple {
    background-position: center;
    transition: background 0.8s;
}

#root {
    overflow: hidden;
}

.scroll-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.with-scroll {
    flex-grow: 1;
    overflow-y: auto;
    min-height: 0;
}

.buttons-container {
     margin-top: 16px;
}

input {
    outline: none;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $logo-dark;
        opacity: 0.4; /* Firefox */
    }
}
.without-outline {
    outline: none;
}
.invisible {
    visibility: hidden;
    opacity: 0;
}

.display-none {
    display: none;
}

.text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.react-tooltip-lite {
    background: $tooltip-gray;
    border-radius: 5px;
    color: $theme-base-white;
    white-space: break-spaces;
    max-width: 400px !important;
    line-height: 1.3em;
}

.small {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.01em;
}

.missed {
    font-weight: 600
}

.big-text {
    font-size: 24px;
}

.webMeasurement {
    position: relative;
    max-width: 100vw;
    max-height: 100vh;
    border: 1px solid #ccc;
    border-radius: 5px;
    transform: rotate(0);
    overflow: hidden;
}

@media screen and (min-width: $webSize) {
    .set_containing_block {
        position: relative;
    }

    .webMeasurement {
        position: relative;
        max-width: 320px;
        max-height: 595px;
    }
}

@media screen and (max-width: $popupSize) {
    .webMeasurement {
        border: none;;
    }
}

.selected {
    color: $green;
}

.border-bottom {
    border-bottom: 1px solid $light-border-grey;
}   

.padding-screen {
    padding-left: 16px;
    padding-right: 16px
}



.border-input {
    border: 1px solid $light-grey-text;
}

.padding-input {
    padding: 8px 8px 8px 38px; 
}

.border-radius {
    border-radius: 4px;
}

.search-icon-input {
    position: absolute;
    left: 25px;
    font-size: 24px;
    bottom: 9px;
    transform: translateY(-50%);
}

.relative {
    position: relative;
}


.main-black {
    color: $theme-base-dark;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.inactive {
    display: none;
}