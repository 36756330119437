.container {
    padding: 25px;
}

.divider {
    flex: 1;
    height: 1px;
    background-color: #ccc;
}

._2faContainer {
    padding-left: 16px;
    padding-right: 16px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    i {
        font-size: 24px;
        margin-top: 32px;
        cursor: pointer;
    }
    ._2faCodeContainer {
        display: flex;
        margin-top: 6em;
        flex-direction: column;
        gap: 8px;
        flex-grow: 1;
        div > label > input {
            margin-top: 12px;
        }
        .codeInputSection {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            align-items: start ;
            .inputWrapper {
                width: 90%;
            }
            .resendSection {
                display: flex;
                flex-direction: column;
                width: 150px;
                margin-top: 14px;
                &.resendMargin {
                    margin-top: 22px;
                }
                p > span {
                    color: #3ACF7D;
                    font-weight: 600;
                }
                .resendButton {
                    padding: 0;
                    span {
                        color: #6AA8EE;
                        font-size: 13px;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .buttonContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 32px;
    }
}

