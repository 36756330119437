@import "styles/colors.scss";
@import "styles/sizes.scss";

.queue-indicator {
    position: relative;
    margin-left: 0.5em;
    padding-top: 1em;
    cursor: pointer;

    i {
        font-size: 24px;
    }

    .queue-counter {
        background-color: red;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        position: absolute;
        font-size: 9px;
        left: 13px;
        text-align: center;
        line-height: 16px;
        color: white;
        font-weight: 600;
    }
}
